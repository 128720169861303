import React, { useRef, useEffect } from 'react';

import { ChatMessage, User } from '../types';
import { Message } from '../messages/message';
import { MessageListContainer } from './messageList.styles';

export type MessageListProps = {
  messages: ChatMessage[];
  users: Record<string, User>;
};

export const MessageList = ({ messages = [], users }: MessageListProps) => {
  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <MessageListContainer className={'message-list'} ref={messageListRef}>
      {messages.map(m => (
        <Message key={m.id} message={m} user={users[m.user]} />
      ))}
    </MessageListContainer>
  );
};
