import { useState } from 'react';

import {
  botFlows,
  BotFlow,
  BotFlowSummary,
  BotFlowSummaryItem,
  BotFlowSummarySelectedAnswer,
  BotFlowStep,
  MessageLink,
} from '../../constants/definitions';

type QuestionMessageMeta = {
  type: 'question';
  links?: MessageLink[];
  stepName: string;
  text: string;
  id: string;
};

type VideoMessageMeta = {
  type: 'video';
  stepName: string;
  videoUrl: string;
  id: string;
};

type AnswerMeta = { text: string; nextStepName: string };
type AnswerMessageMeta = {
  type: 'answer';
  answers: AnswerMeta[];
  selectedAnswer: string | undefined;
  stepName: string;
  id: string;
};

type UserMessageMeta = {
  type: 'user';
  text: string;
  stepName: string;
  id: string;
};

type SummaryMessageMeta = {
  type: 'summary';
  text: string;
  stepName: string;
  id: string;
};

type MessageMeta =
  | QuestionMessageMeta
  | AnswerMessageMeta
  | UserMessageMeta
  | SummaryMessageMeta
  | VideoMessageMeta;

// finding the query parameter.
function getUrlVars(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }

  return undefined;
}

// get flow parameter.
function getFlowParam() {
  const flowParam = getUrlVars('flow') || 'General';
  const availableSymptoms = new Set(['general', 'lizzy', 'randy', 'anna']);
  if (availableSymptoms.has(flowParam.toLowerCase())) {
    return flowParam[0].toUpperCase() + flowParam.substr(1).toLowerCase();
  }
  return 'General';
}

export function useChatWorkflow(
  initialWorkflow: keyof typeof botFlows = 'questionnaire',
  initialState?: BotFlowSummary
) {
  const [workflowName] = useState<keyof typeof botFlows>(initialWorkflow);
  const workflow = botFlows[workflowName];
  const firstStep = 'step-1';

  const [summary, setSummary] = useState<BotFlowSummary>(
    initialState || { [firstStep]: { ...workflow[firstStep] } }
  );

  const [messages, setChatflowMessages] = useState<MessageMeta[]>(() =>
    getMessagesFromSummary(summary, workflow, firstStep)
  );

  const submitAnswer = (
    stepName: string,
    answer: BotFlowSummarySelectedAnswer
  ) => {
    const updatedSummary = updateSummaryStep(
      summary,
      stepName,
      answer,
      workflow
    );

    setSummary(updatedSummary);

    setChatflowMessages(currentMessages => {
      const newMessages = [...currentMessages];

      for (let i = currentMessages.length - 1; i >= 0; i--) {
        const message = currentMessages[i];

        if (message.type === 'answer' && message.stepName === stepName) {
          message.selectedAnswer = answer.name;
          break;
        }
      }

      const currentStep = workflow[stepName];
      const nextStepName =
        currentStep.type === 'question'
          ? currentStep.Answers![answer.name]
          : currentStep.nextStep;

      newMessages.push({
        type: 'user',
        stepName,
        text: answer.freeFormText || answer.name,
        id: 'user' + stepName,
      });

      let nextStep = workflow[nextStepName];

      if (!nextStep) {
        return newMessages;
      }

      if (nextStep.type === 'video') {
        newMessages.push({
          id: nextStepName + 'video',
          videoUrl: nextStep.videoUrl,
          stepName: nextStepName,
          type: 'video',
        });

        nextStep = workflow[nextStep.nextStep];
      }

      if (nextStepName === 'done') {
        const user =
          getFlowParam() === 'Anna' || getFlowParam() === 'Randy'
            ? getFlowParam()
            : '';
        nextStep.Question[0] =
          user !== ''
            ? nextStep.Question[0].replace('{userName}', `, ${user}`)
            : nextStep.Question[0].replace('{userName}', user);
      }

      newMessages.push({
        type: 'question',
        id: nextStepName + 'question',
        text: nextStep.Question.join('\n'),
        stepName: nextStepName,
      });

      if (nextStep.type === 'question' && nextStep.Answers) {
        const answers =
          nextStep.Answers &&
          Object.entries(nextStep.Answers).filter(
            ([label]) => label !== 'Type in Answer'
          );
        if (answers?.length) {
          newMessages.push({
            id: nextStepName + 'answer',
            answers: answers.map<AnswerMeta>(([key, value]) => ({
              text: key,
              nextStepName: value,
            })),
            selectedAnswer: undefined,
            stepName: nextStepName,
            type: 'answer',
          });
        }
      }

      return newMessages;
    });
  };

  let disableInput = false;

  const nextStepName = messages[messages.length - 1]?.stepName;
  if (nextStepName && workflow[nextStepName]) {
    const nextStep = workflow[nextStepName];

    if (nextStep.type === 'video') {
      disableInput = true;
    } else if (
      nextStep.type === 'question' &&
      (!nextStep.Answers || !('Type in Answer' in nextStep.Answers))
    ) {
      disableInput = true;
    }
  }

  const submitFreeformAnswer = (stepName: string, answer: string) => {
    const answerName = 'Type in Answer';

    const step = summary[stepName];

    if (step?.type === 'question' && !step?.Answers?.[answerName]) {
      return;
    }

    submitAnswer(stepName, { name: answerName, freeFormText: answer });
  };

  return {
    disableInput,
    messages,
    submitAnswer,
    submitFreeformAnswer,
    summary,
  };
}

export function getMessagesFromSummary(
  summary: BotFlowSummary | undefined,
  workflow: BotFlow,
  firstStep: string
): MessageMeta[] {
  const m: MessageMeta[] = [];

  if (!summary) {
    return m;
  }

  let key: string | undefined = firstStep;
  while (key) {
    const capturedKey: string = key;

    const step: BotFlowSummaryItem | BotFlowStep | undefined =
      summary[capturedKey] ?? workflow[capturedKey];

    if (!step || !Object.keys(step).length) {
      break;
    }

    m.push({
      type: 'question',
      id: capturedKey + 'question',
      links: step.type === 'question' ? step.links : undefined,
      text: step.Question.join('\n'),
      stepName: capturedKey,
    });

    if (step.type === 'question' && step.Answers) {
      const answers = Object.entries(step.Answers).filter(
        ([label]) => label !== 'Type in Answer'
      );

      if (answers.length) {
        m.push({
          id: capturedKey + 'answer',
          answers: answers.map<AnswerMeta>(([key, value]) => ({
            text: key,
            nextStepName: value,
          })),
          selectedAnswer: (step as any).selectedAnswer?.name,
          stepName: capturedKey,
          type: 'answer',
        });
      }
    }

    if ((step as any).selectedAnswer) {
      m.push({
        type: 'user',
        text:
          (step as any).selectedAnswer.freeFormText ||
          (step as any).selectedAnswer.name,
        stepName: capturedKey,
        id: 'user' + capturedKey + 'answer',
      });
    }

    if (
      step.type === 'question' &&
      step.Answers &&
      (step as any).selectedAnswer
    ) {
      key = step.Answers[(step as any).selectedAnswer.name];
    } else if (step.type === 'video') {
      m.push({
        id: step.nextStep + 'video',
        videoUrl: step.videoUrl,
        stepName: step.nextStep,
        type: 'video',
      });

      key = step.nextStep;
    } else {
      key = undefined;
    }
  }

  return m;
}

export function updateSummaryStep(
  summary: BotFlowSummary,
  stepName: string,
  newAnswer: BotFlowSummarySelectedAnswer,
  workflow: BotFlow
) {
  const copy: BotFlowSummary = JSON.parse(JSON.stringify(summary));

  let step = copy[stepName];

  if (!step) {
    step = copy[stepName] = { ...workflow[stepName] };
  }

  if (!step) {
    return {
      ...copy,
      [stepName]: { ...workflow[stepName] },
    };
  }

  if (
    step.type === 'question' &&
    step.selectedAnswer &&
    step.selectedAnswer.name !== newAnswer.name &&
    newAnswer.name !== 'Back to review'
  ) {
    // check if the new answer actually no longer use dependencies but the old answer does.
    // and delete the answers of those dependencies.
    // const newAnswerName = (newAnswer || { name: '' }).name;
    const allStepAnswers = step.Answers;
    if (allStepAnswers) {
      if (
        !allStepAnswers[newAnswer.name].includes(stepName + 'i') &&
        allStepAnswers[step.selectedAnswer.name].includes(stepName + 'i')
      ) {
        let subStepName = stepName + 'i';
        while (workflow[subStepName] && summary[subStepName].selectedAnswer) {
          summary[subStepName].selectedAnswer = undefined;
          subStepName += 'i';
        }
      }
    }
    summary[stepName].selectedAnswer = newAnswer;
  }

  if (newAnswer.name !== 'Back to review') {
    step.selectedAnswer = newAnswer;
  }

  return copy;
}
