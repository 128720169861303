import React, {
  forwardRef,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  RefForwardingComponent,
} from 'react';

import { Button as StyledButton } from './button.styles';

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ButtonComponent: RefForwardingComponent<
  HTMLButtonElement,
  ButtonProps
> = (props, ref) => {
  return <StyledButton {...props} ref={ref} />;
};

export const Button = forwardRef(ButtonComponent);
