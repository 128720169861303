import React from 'react';

import { TextMessage as TextMessageType } from '../../types';

export type TextMessageProps = {
  message: TextMessageType;
};

export const TextMessage = ({ message }: TextMessageProps) => {
  return <div className="msg-wrapper">{message.message}</div>;
};
