import styled from 'styled-components';

export const Input = styled.input`
  background: none;
  border: none;
  border-top: 1px solid #e0e0e0;
  flex: 1;
  padding: 12px 24px;
  width: 100%;
`;

export const SendButton = styled.button`
  border: none;
  padding: 8px;
`;

export const Form = styled.form`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const UserInputContainer = styled.div`
  background-color: aliceblue;
  height: 60px;
  width: 100%;
  transition: background-color 150ms ease;
  grid-area: input;

  :focus-within {
    background-color: #e6f3ff;
  }
`;
