import styled from 'styled-components';

export const ChatWindowContainer = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content' 'input';
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

export const WindowHeader = styled.div`
  background: rgb(0, 107, 166);
  color: white;
  padding: 24px;
  grid-area: header;
`;
