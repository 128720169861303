import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  margin: 0 16px;
  padding-bottom: 16px;
`;

type MessageContentProps = {
  isSelf: boolean;
};
export const MessageContent = styled.div<MessageContentProps>`
  width: 80%;
  background: ${props => (props.isSelf ? '#4e8cff' : '#f4f7f9')};
  ${props => (props.isSelf ? 'color: white;' : '')};
  border-radius: 8px;
  padding: 8px;
  ${props => (props.isSelf ? 'margin-left: auto;' : '')}
`;
