import React, { ReactNode } from 'react';

import { ChatMessage, User } from './types';
import { MessageList } from './messageList/messageList';
import { ChatWindowContainer, WindowHeader } from './chatWindow.styles';
import { UserInput } from './userInput/userInput';

export type ChatWindowProps = {
  disableInput?: boolean;
  messages: ChatMessage[];
  onMessageSent?: (message: ChatMessage) => void;
  title?: ReactNode;
  users?: Record<string, User>;
};

export const ChatWindow = ({
  disableInput = false,
  onMessageSent,
  messages,
  title = 'KPBot',
  users = {},
}: ChatWindowProps) => {
  return (
    <ChatWindowContainer className={'chat-window'}>
      <WindowHeader>{title}</WindowHeader>
      <MessageList messages={messages} users={users} />
      <UserInput disabled={disableInput} submitMessage={onMessageSent} />
    </ChatWindowContainer>
  );
};
