import React from 'react';
import ReactPlayer from 'react-player';

import { VideoMessage as VideoMessageType } from '../../types';

export type VideoMessageProps = {
  message: VideoMessageType;
};

export const VideoMessage = ({ message }: VideoMessageProps) => {
  return (
    <div className="msg-wrapper">
      <ReactPlayer url={message.videoUrl} width={'100%'} />
    </div>
  );
};
