import styled from 'styled-components';

export const StyledActionMessage = styled.div`
  display: flex;
  flex-direction: column;

  button:not(:last-child) {
    margin-bottom: 8px;
  }
`;
