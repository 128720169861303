import React from 'react';

import { CustomMessage as CustomMessageType } from '../../types';

export type CustomMessageProps = {
  message: CustomMessageType;
};

export const CustomMessage = ({ message }: CustomMessageProps) => {
  return <>{message.message}</>;
};
