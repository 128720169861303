import styled from 'styled-components';

export const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 40px 0;
  position: relative;
  grid-area: content;
`;
