import React, { useState, useRef, useEffect } from 'react';

import { ChatMessage } from '../types';
import {
  Input,
  UserInputContainer,
  SendButton,
  Form,
} from './userInput.styles';

export type UserInputProps = {
  disabled?: boolean;
  submitMessage?: (message: ChatMessage) => void;
};

export const UserInput = ({ disabled, submitMessage }: UserInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (!disabled) {
      inputRef.current && inputRef.current.focus();
    }
  }, [disabled]);

  return (
    <UserInputContainer>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (value.trim()) {
            submitMessage &&
              submitMessage({
                id: Date.now().toString(),
                message: value,
                type: 'text',
                user: 'me',
              });

            setValue('');
          }
        }}
      >
        <Input
          disabled={disabled}
          onChange={e => setValue(e.currentTarget.value)}
          placeholder={'Type your message...'}
          ref={inputRef}
          value={value}
        />
        <SendButton disabled={disabled} type={'submit'}>
          Send
        </SendButton>
      </Form>
    </UserInputContainer>
  );
};
