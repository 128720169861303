import React, { ReactNode } from 'react';

import { AvatarContainer, CircularContainer } from './avatar.styles';

export type AvatarProps = {
  name: string;
  profilePictureUrl?: string;
};

export const Avatar = ({ name, profilePictureUrl }: AvatarProps) => {
  let content: ReactNode;
  if (profilePictureUrl) {
    content = <img alt={'User Profile'} src={profilePictureUrl} />;
  } else {
    content = name[0].toUpperCase();
  }

  return (
    <AvatarContainer>
      <CircularContainer>{content}</CircularContainer>
    </AvatarContainer>
  );
};
