import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { ChatMessage, User } from '../types';
import { TextMessage } from './content/textMessage';
import { MessageContainer, MessageContent } from './message.styles';
import { CustomMessage } from './content/customMessage';
import { Avatar } from '../avatar/avatar';
import { VideoMessage } from './content/videoMessage';

export type MessageProps = {
  message: ChatMessage;
  user: User | undefined;
};

export const Message = ({ message, user }: MessageProps) => {
  let content: ReactNode;

  switch (message.type) {
    case 'text':
      content = <TextMessage message={message} />;
      break;

    case 'custom':
      content = <CustomMessage message={message} />;
      break;

    case 'video':
      content = <VideoMessage message={message} />;
      break;
  }

  return (
    <MessageContainer
      className={clsx('message', message.user === 'me' && 'me')}
    >
      {(message.displayAvatar === true ||
        (message.displayAvatar !== false && message.user !== 'me')) && (
        <Avatar
          name={(user && user.displayName) || message.user}
          profilePictureUrl={user && user.profilePictureUrl}
        />
      )}
      <MessageContent
        className={'message-content'}
        isSelf={message.user === 'me'}
      >
        {content}
      </MessageContent>
    </MessageContainer>
  );
};
