export type MessageLink = {
  text?: string;
  url: string;
};

export type BotFlowVideoStep = {
  videoUrl: string;
  nextStep: string;
  Question: string[];
  type: 'video';
};

export type BotFlowQuestionStep = {
  backToReview?: boolean;
  Answers?: Record<string, string>;
  Question: string[];
  Summary?: Record<string, string[]>;

  /**
   * Links to include in question text. Each message in the array will be
   * referenced in questions with {0} syntax where the number is an index
   * within the array.
   *
   * @example
   * links: [{ url: 'https://google.com' }, { url: 'https://clubpenguin.com' }],
   * Question: ['hello {1}, {0}']
   * // {0} is replaced with google.com, {1} is replaced with clubpenguin.com
   */
  links?: MessageLink[];
  type: 'question';
};

export type BotFlowStep = BotFlowQuestionStep | BotFlowVideoStep;

export type BotFlow = {
  [stepName: string]: BotFlowStep;
};

export type BotFlowSummarySelectedAnswer = {
  freeFormText?: string;
  name: string;
};

export type BotFlowSummaryItem = BotFlowStep & {
  selectedAnswer?: BotFlowSummarySelectedAnswer;
};

export type BotFlowSummary = {
  [stepName: string]: BotFlowSummaryItem;
};

const flow: BotFlow = {
  ['step-1']: {
    Question: ['Hi there!', 'To get started, please tell me your full name.'],
    Answers: {
      'Type in Answer': 'step-2',
    },
    type: 'question',
  },
  ['step-2']: {
    Question: [
      'Nice to meet you!',
      'Can you tell me your email address so we can stay in contact?',
    ],
    Answers: {
      'Type in Answer': 'step-3',
    },
    type: 'question',
  },
  ['step-3']: {
    Question: ['Address'],
    Answers: {
      'Type in Answer': 'step-4',
    },
    type: 'question',
  },
  ['step-4']: {
    Question: ['Phone'],
    Answers: {
      'Type in Answer': 'requestedService',
    },
    type: 'question',
  },
  ['requestedService']: {
    Question: [
      'Do you want a Will or Trust?',
      "If you're not sure, tell me, and I'll provide a video to help you decide.",
    ],
    Answers: {
      Will: 'will-1',
      Trust: 'trust-1',
      "I don't know": 'will-trust-video',
    },
    type: 'question',
  },
  ['will-trust-video']: {
    Question: ['Check out this helpful video: '],
    videoUrl: 'https://www.youtube.com/watch?v=6oEwsdaVDI0&feature=emb_title',
    nextStep: 'will-trust-confirm',
    type: 'video',
  },
  ['will-trust-confirm']: {
    Question: ['Now, do you want a Will or a Trust?'],
    Answers: {
      Will: 'will-1',
      Trust: 'trust-1',
    },
    type: 'question',
  },
  ['will-1']: {
    Question: [
      "Sounds great! That's all we need for now. Click {0} to continue.",
    ],
    links: [{ url: 'https://google.com', text: 'here' }],
    type: 'question',
  },
  ['trust-1']: {
    Question: [
      "Sounds great! That's all we need for now. Click {0} to continue.",
    ],
    links: [{ url: 'https://google.com', text: 'here' }],
    type: 'question',
  },
};

export const botFlows = {
  questionnaire: flow,
};
