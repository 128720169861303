import React, { useEffect, ReactNode } from 'react';
import { debounce } from 'lodash-es';

import { useChatWorkflow } from './useChatWorkflow';
import { ChatWindow } from '../chat/chatWindow';
import { ChatMessage } from '../chat/types';
import { ActionMessage } from './actionMessage/actionMessage';
import { SelectedButton } from '../button/button.styles';
import { Button } from '../button/button';
import { BotFlowSummary } from '../../constants/definitions';

const debouncedSync = debounce(syncToServer, 1000, {
  trailing: true,
});

type ChatFlowProps = {
  initialSummary: BotFlowSummary;
  onSummaryUpdated: (newSummary: BotFlowSummary) => void;
};

const ChatFlow = ({ initialSummary, onSummaryUpdated }: ChatFlowProps) => {
  const {
    disableInput,
    messages,
    submitFreeformAnswer,
    submitAnswer,
    summary,
  } = useChatWorkflow('questionnaire', initialSummary);

  const chatMessages = messages
    .map<ChatMessage | undefined>((m, i) => {
      switch (m.type) {
        case 'answer':
          const answers = m.answers.filter(({ text }) => text !== 'Free Text');

          if (answers.length) {
            return {
              id: m.id + i,
              type: 'custom',
              user: 'bot',
              message: (
                <ActionMessage>
                  {m.answers.map(answer => {
                    const Component =
                      !!m.selectedAnswer && m.selectedAnswer === answer.text
                        ? SelectedButton
                        : Button;

                    return (
                      <Component
                        disabled={!!m.selectedAnswer}
                        key={m.id + answer.text}
                        onClick={() =>
                          submitAnswer(m.stepName, {
                            name: answer.text,
                          })
                        }
                      >
                        {answer.text}
                      </Component>
                    );
                  })}
                </ActionMessage>
              ),
            };
          }
          break;

        case 'question':
          const messages: ReactNode[] = [];
          let lastIndex = 0;

          if (m.links) {
            const regexp = /\{(\d)\}/g;

            let segmentStartIndex = 0;
            let matches: RegExpExecArray | null;
            // For each match in the message... ({0}, {1}, etc)
            while ((matches = regexp.exec(m.text)) !== null) {
              segmentStartIndex = matches.index;

              const url = m.links[Number(matches[1])].url;
              const linkText = m.links[Number(matches[1])].text ?? url;

              // Add all text that came between our last match and the beginning of the current match
              messages.push(m.text.substring(lastIndex, segmentStartIndex));

              // Then, add the link
              messages.push(
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {linkText}
                </a>
              );
              lastIndex = segmentStartIndex + matches[0].length;
            }
          }

          // Add the rest of the message, if any
          if (lastIndex !== m.text.length) {
            messages.push(m.text.substring(lastIndex, m.text.length));
          }

          return {
            id: m.id,
            type: 'custom',
            message: messages,
            user: 'bot',
          };

        case 'user':
          return {
            id: m.id,
            type: 'text',
            message: m.text,
            user: 'me',
          };

        case 'video':
          return {
            id: m.id,
            type: 'video',
            user: 'bot',
            videoUrl: m.videoUrl,
          };

        case 'summary':
        default:
          return undefined;
      }
    })
    .filter(Boolean);

  useEffect(() => {
    debouncedSync(onSummaryUpdated, summary);
  }, [onSummaryUpdated, summary]);

  return (
    <ChatWindow
      disableInput={disableInput}
      messages={chatMessages as ChatMessage[]}
      onMessageSent={message => {
        const currentMessage = messages[messages.length - 1];

        submitFreeformAnswer(
          currentMessage.stepName,
          message.type === 'text' ? (message.message as string) : ''
        );
      }}
      title={'CLT Firm'}
      users={{ bot: { displayName: 'John' } }}
    />
  );
};

export default ChatFlow;

function syncToServer(
  fn: (newSummary: BotFlowSummary) => void,
  summary: BotFlowSummary
) {
  fn(summary);
}
