import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import SEO from '../components/seo';
import ChatFlow from '../components/chatFlow/chatFlow';
import { UserSession } from '../models/api/userSession';
import { ApiClient } from '../services/apiClient';

const SecondPage = () => {
  const [user, setUser] = useState<
    { userId: string; sessionId: string } | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [userSession, setSession] = useState<UserSession | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      // /user/{userId}/session/{sessionId}
      const pathParts = window.location.pathname.split('/');
      const userId = pathParts[2];
      const sessionId = pathParts[4];

      try {
        const existingSession = await ApiClient.getSession(userId, sessionId);

        setSession(existingSession);
        setUser({ sessionId, userId });
      } catch (e) {
        console.error('session not found');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return 'Loading';
  } else if (!userSession) {
    return (
      <>
        Session not found. <Link to={'/'}>Click here to start over.</Link>
      </>
    );
  }

  return (
    <>
      <SEO title="CLT Firm Chat" />
      <StyledContainer>
        <ChatFlow
          initialSummary={
            userSession?.summary ? JSON.parse(userSession.summary) : undefined
          }
          onSummaryUpdated={summary => {
            if (!user?.userId || !user?.sessionId) {
              return;
            }

            if (!loading) {
              ApiClient.updateSession(user?.userId, user?.sessionId, {
                summary: JSON.stringify(summary),
              });
            }
          }}
        />
      </StyledContainer>
    </>
  );
};

export default SecondPage;

const StyledContainer = styled.div`
  flex: 1;
`;
