import styled from 'styled-components';

export const AvatarContainer = styled.div`
  padding: 8px;
  height: 40px;
  width: 40px;
`;

export const CircularContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgb(0, 107, 166);
  border-radius: 50%;
  color: white;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
