import styled from 'styled-components';

export const Button = styled.button`
  -webkit-appearance: button;

  background-color: white;
  border: 1px solid lightgray;
  border-radius: 40px;
  font-family: inherit;
  padding: 12px 18px;
  transition: background-color 150ms ease;

  :not([disabled]) {
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    :active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const SelectedButton = styled(Button)`
  background: #4e8cff;
  color: white;
`;
