import React, { ReactNode } from 'react';
import { StyledActionMessage } from './actionMessage.styles';

export type ActionMessageProps = {
  children: ReactNode;
};

export const ActionMessage = ({ children }: ActionMessageProps) => {
  return <StyledActionMessage>{children}</StyledActionMessage>;
};
